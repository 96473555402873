<template>
  <div>
    <div>
      <img class="top_image" :src="menu.bannerImg" alt=""/>
    </div>
    <!-- 面包屑 -->
    <div class="mbx">
      <div style="width: 1200px; margin: 20px auto">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size:16px">
            <router-link to="/">首页</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item style="font-size:16px">车源信息</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <p class="small_line"></p>
    </div>
    <div class="fgx">
      <p class="title"><span>车源信息</span></p>
      <div class="line">
        <p class="linebg"></p>
      </div>
    </div>
    <!-- 车源信息 -->
    <div class="news">
      <!-- 车源信息列表 -->
      <div class="newscontent">
        <el-table @row-click="openDetails" :data="carInfoList" v-loading="loading" style="width: 100%">
          <el-table-column prop="CAR_TYPE" label="车型" width="180"></el-table-column>
          <el-table-column prop="CAR_NO" label="车牌号" width="140">
            <template slot-scope="scope">
              <el-link @click="openDetails(scope.row)" type="primary" :underline="false">{{scope.row.CAR_NO}}</el-link>
            </template>
          </el-table-column>
<!--          <el-table-column prop="PLATECOLOR" label="车牌颜色" width="140"></el-table-column>-->
          <el-table-column prop="MAX_TONNAGE" label="载重(KG)" width="100"></el-table-column>
          <el-table-column prop="BOARD" label="轮廓(米)" width="150"></el-table-column>
<!--          <el-table-column prop="LINK_UNIT" label="公司(个人)" width="240"></el-table-column>-->
<!--          <el-table-column prop="USER_NAME" label="联系人" width="100"></el-table-column>-->
          <el-table-column prop="PHONE" label="联系电话" width="180">
            <template slot-scope="scope">
              {{ scope.row.PHONE }}
            </template>
          </el-table-column>
        </el-table>
        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="pageNum"
            :limit.sync="pageSize"
            @pagination="getCarInfoList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../components/pagination";
import axios from 'axios'
import {getMenuList} from "@/request";

export default {
  components: {
    Pagination
  },
  data() {
    return {
      menu: {
        bannerImg:''
      },
      loading:true,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      carInfoList: []
    }
  },
  created() {
    getMenuList({path: this.$route.path}).then(res => {
      if(res.data[0]) {
        this.menu = res.data[0];
      }
    });
    this.getCarInfoList();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    getCarInfoList() {
      axios.get(window.apiUrl+"/api/common/carInfoList?pageSize="+this.pageSize+"&pageNum="+this.pageNum).then(res => {
        this.total = res.data.total;
        this.carInfoList = res.data.rows;
        this.loading=false;
      });
    },
    openDetails(row){
      this.$router.push({name:'车源详情',params:{id:row.CAR_INFO_ID}});
    },
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.small_line {
  background: #ddd;
  height: 1px;
}

.top_image {
  width: 100%;
}

.fgx {
  width: 1200px;
  margin: 0 auto;
}

.fgx .title {
  font-weight: 900;
  text-align: left;
  margin-top: 30px;
  font-size: 20px;
  margin-bottom: 10px;
}

.fgx .line {
  width: 100%;
  height: 5px;
  background-color: #efefef;
}

.line .linebg {
  height: 5px;
  width: 80px;
  background-color: black;
}

.news .newscontent {
  margin: 0 auto;
  width: 1200px;
  margin-top: 30px;
  text-align: left;
  padding-bottom: 50px;
  text-justify: inter-word;
  background: url("../../static/切图文件/bg-map-1.png") no-repeat top center;
}

.news .newscontent .news_content {
  display: inline-block;
  width: 358px;
  height: 240px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #ddd;
  margin-top: 20px;
  margin-bottom: 30px;
  vertical-align: top;
}

.news .newscontent .news_content .mdimgcontent {
  width: 358px;
  height: 180px;
  position: relative;
}

.news .newscontent .news_content .dw {
  margin: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
  text-align: left;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.news .newscontent .news_content:hover {
  box-shadow: 0 0 20px #aaa;
}
</style>